import React from 'react';

function InfoSection({ heading, grade, tags, link }) {
  return (
    <div className = 'infosection'>
      <div className="info">
        {/* <h3 href className="heading">{heading}</h3> */}
        <h3 className="heading">
          <a href={link} target="_blank">{heading}</a>
        </h3>
        <h3 className="grade">{grade}</h3>
      </div>
      <div className="tags">
        <h3 className="tag">{tags}</h3>
      </div>
    </div>
  );
}

export default InfoSection;